import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    //
    {
        path: '/',
        name: 'Index',
        component: () =>
            import ('@/views/Index.vue')
    },
    {
        path: '/Document',
        name: 'Document',
        component: () =>
            import ('@/views/Document.vue')
    },
    {
        path: '/Video',
        name: 'Video',
        component: () =>
            import ('@/views/Video.vue')
    },
    {
        path: '/VideoDetails',
        name: 'VideoDetails',
        component: () =>
            import ('@/views/VideoDetails.vue')
    },
    {
        path: '/Configuration',
        name: 'Configuration',
        component: () =>
            import ('@/views/Configuration.vue')
    },
    {
        path: '/Probation',
        name: 'Probation',
        component: () =>
            import ('@/views/Probation.vue')
    },
    {
        path: '/Programme',
        name: 'Programme',
        component: () =>
            import ('@/views/Programme.vue')
    },
    {
        path: '/ProgrammeDetails',
        name: 'ProgrammeDetails',
        component: () =>
            import ('@/views/ProgrammeDetails.vue')
    },
    {
        path: '/Animation',
        name: 'Animation',
        component: () =>
            import ('@/views/Animation.vue')
    },
    {
        path: '/AnimationDetails',
        name: 'AnimationDetails',
        component: () =>
            import ('@/views/AnimationDetails.vue')
    },
    {
        path: '/Information',
        name: 'Information',
        component: () =>
            import ('@/views/Information.vue')
    },
    {
        path: '/InformationDetails',
        name: 'InformationDetails',
        component: () =>
            import ('@/views/InformationDetails.vue')
    },
    {
        path: '/Test',
        name: 'Test',
        component: () =>
            import ('@/views/Test.vue')
    },



]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router